:root {
  --bg-color: rgba(17, 17, 17);
  --bg-color: #ffffff;
  --nav-bg-color: linear-gradient(180deg, #3655f8 0%, #43bbff 100%);
  --text-color: #ffffff;
  --logo-color: #000000;
  --heading-color: #424242;
  --paragraph-color: #484848;
  --mobile-nav-bg: #ebebeb;
  --mobil-nav-link-color: #1d1d1d;

  --service-box-bg: #faf8fc;
  --service-icon: #3655f8;
  --service-box-border: #ccc;
}
body {
  /* background-color: var(--bg-color); */
  /* color: var(--text-color); */
  --bg-color: #ffffff;
  background-color: var(--bg-color);
  overflow-x: hidden !important;
}
h2,
h3,
h4,
h5 {
  color: var(--heading-color);
}
.homecontainer {
  position: relative;
  height: 90vh;
  width: 100%;
  transition: all 0.3s ease;
  margin: 0 !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.darkmode {
  /* --bg-color:#232323; */
  --bg-color: #1d1d1d;
  --nav-bg-color: #222222;
  --heading-color: #ececec;
  --paragraph-color: #f4f4f4;
  --text-color: #ffffff;
  --logo-color: #f3f3f3;
  --mobile-nav-bg: #333333;
  --mobil-nav-link-color: #ffffff;

  --service-box-bg: #2c2c2c;
  --service-box-border: #393939;
}
.main-head {
  background-color: transparent;
  transition: all 0.3s ease;
  /* position: fixed;
    top: 0;
    width: 100vw; */
  z-index: 130 !important;
}
/* .dummydiv{
    max-height: 10vh;
    max-width: 100vw;
  } */
header {
  /* background-color: #d6471b; */
  height: 10vh;
  width: 90%;
  margin: 0 auto;
  z-index: 130;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
}
.logo {
  font-size: x-large;
  font-weight: bold;
  text-decoration: none;
  color: var(--heading-color);
  animation: sliddown 1s linear forwards;
}
@keyframes slidedown {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0);
  }
}
nav ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
nav ul li {
  margin-left: 0.25rem;
}
nav ul li a {
  color: #ffffff;
  font-weight: 500;
  /* font-size:1.2rem; */
  line-height: 28.13px;
  text-decoration: none;
  padding: 0.4rem 1rem;

  border-radius: 8px;
}
.active {
  border: 1px solid #fff;
}
#togglebtn,
#togglebtnmob {
  all: unset;
  height: 35px;
  width: 35px;
  background-color: rgba(255, 255, 255, 0.404);
  border-radius: 8px;
  color: #ffffff;
  display: grid;
  place-items: center;
  cursor: pointer;
  font-size: 1.3rem;
  padding: 0;
}
.burger,
#togglebtnmob {
  color: white;
  display: none;
}

.background_gradient {
  all: unset;
  z-index: -99;
  position: absolute;
  height: 100vh;
  width: 70vw;
  animation: linearbgani 0.8s linear forwards;
  text-align: right;
  user-select: none;
}
.otherpagebg {
  animation: none !important;
  right: 100%;
}
.background_gradient img {
  max-height: 100%;
  height: 1903px;
  max-width: 100%;
  width: 1977px;
  user-select: none;
}
@keyframes linearbgani {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}
h1 {
  font-size: 48px;
  font-weight: bold;
  font-family: "Righteous", cursive;
  /* color: #424242; */
  color: var(--heading-color);
  transition: all 0.3s ease;
}
.animatedboybox {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: -1 !important;
  height: auto;
  width: auto;
}
.speechcloud {
  height: 300px;
  width: 300px;
  position: absolute;
  right: 75%;
  opacity: 0;
  animation: popupcloud 1s linear forwards;
  animation-delay: 0.5s;
  transition: all 0.3s ease-in;
}
@keyframes popupcloud {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.speech {
  position: absolute;
  top: 40%;
  left: 40%;
  padding-left: 1rem;
  transform: translate(-50%, -50%);
  z-index: 112;
  max-width: 200px;
  font-size: large;
  font-family: righteous, sans-serif;
  text-transform: capitalize;

  transition: all 0.3s ease-in;
}

.speechcloud img {
  max-height: 100%;
  max-width: 100%;
  transform: scale(2);
}
.animatedboybox img {
  height: 75vh;
  border-radius: 40% 30% 0 0;
  min-width: auto;
  z-index: -100 !important;
  user-select: none;
  animation: imagein 0.8s linear forwards;
}
@keyframes imagein {
  from {
    transform: translate(0px, 100%);
  }
  to {
    transform: translate(-20px, 4px);
  }
}
.animatedboybox img:hover {
  cursor: pointer;
}

.hometxtbox {
  max-width: 70%;
  margin-left: 5%;
  position: fixed;
  top: 15vh;
  height: auto;
}

.hometxtbox h1 div {
  width: 300px;
  max-width: 90%;
}
.hometxtbox p {
  /* color: #484848; */
  color: var(--paragraph-color);
  transition: all 0.3s ease;
  line-height: 1.7;
  width: 440px;
  padding-right: 1rem;
  max-width: 100%;
}
.hometxtbox a {
  text-decoration: none;
}
.hometxtbox button {
  all: unset;
  text-align: center;
  width: 200px;
  height: 50px;
  cursor: pointer;
  background: linear-gradient(90deg, #0029ff 0%, #41a1f9 100%);
  box-shadow: 2px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-size: 1.2rem;
  color: #ffffff;
}

.mobilenav {
  height: 50px;
  width: 50px;
  background-color: var(--mobile-nav-bg);
  position: fixed;
  z-index: 52 !important;
  transform: translate(120vw, 0vh);
  transition: all 0.5s ease-in-out;
  border-radius: 50% 0 50% 50%;
  opacity: 0.5;
}
.activemobilenav {
  height: 90vh;
  width: 100%;
  border-radius: 0%;
  opacity: 1;
  transform: translate(0vw, 0vh);
}
.mobilenav ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 80%;
  padding: 1rem 0;
  list-style: none;
}

.mobilenav ul li a {
  text-decoration: none;
  padding: 1rem 4rem;
  /* width: 100%; */
  color: var(--mobil-nav-link-color);
  border-radius: 8px;
  border-color: var(--heading-color);
}

@keyframes navdown {
  from {
    transform: translateX(-100vw);
  }
  to {
    transform: translateX(0);
  }
}

.socialmedia {
  min-width: 230px;
  max-width: auto;
  min-height: 40px;
  margin-left: 5%;
  /* padding: 1rem; */
  position: fixed;
  bottom: 5vh;
  /* background-color: #f8f8f8; */
  /* background-color: var(--mobile-nav-bg); */
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.socialmedia a {
  border-radius: 8px;
  padding: 0.5rem 0.8rem;
  color: #f3f3f3;
  font-size: 1.5rem;
  margin-right: 10px;
  transition: all 0.3s ease;
  background: linear-gradient(45deg, rgb(0, 136, 248), rgb(89, 89, 253))
    border-box;
}
.socialmedia a:hover {
  transform: translate(0, -20%);
}
.socialmedia a i {
  width: 100%;
}
@media (max-width: 480px) {
  .socialmedia {
    border: none;
    bottom: 2vh;
    max-width: 80%;
    left: 50%;
    margin: 0;
    transform: translate(-50%);
    color: #fff !important;
  }
  .socialmedia a {
    border-radius: 8px;
    padding: 0.2 0.5rem;
    color: #f3f3f3;
    font-size: 1.2rem;
    margin-right: 10px;

    background: linear-gradient(45deg, rgb(0, 136, 248), rgb(89, 89, 253))
      border-box;
  }
}

@media (max-width: 1140px) {
  .speechcloud {
    display: none;
  }
}
@media (max-width: 960px) {
  nav {
    display: none;
    z-index: 999;
  }
  .burger {
    display: flex;
    user-select: none;
    z-index: 120;
    z-index: 999;
  }
  .ham {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    display: grid;
    place-items: center;
    cursor: pointer;
    user-select: none;
  }
  #togglebtnmob {
    display: grid;
    margin: 0 10px;
  }
}
@media screen and (max-width: 800px) {
  /* :root {
      --heading-color: rgba(255, 255, 255, 0.801);
      --paragraph-color: rgba(250, 250, 250);
    } */
  body {
    background-color: var(--bg-color);
    overflow-x: hidden !important;
  }
  .logo {
    color: #f3f3f3 !important;
  }
  h1 {
    color: var(--heading-color);
  }
  p {
    color: var(--paragraph-color);
  }
  .hometxtbox {
    max-width: 100vw;
    padding: 0 2rem;
    margin: -5vh auto 0 auto;
    position: relative;
    display: block;
    z-index: 50;
  }
  .hometxtbox p {
    width: 50%;
  }
  .animatedboybox {
    bottom: 10% !important;
    right: 0;
  }
  .animatedboybox {
    position: absolute;
    z-index: -5;
    height: 38vh;
    bottom: 0;
  }
  .animatedboybox img {
    height: 60vh;
  }

  .background_gradient {
    display: none;
  }
}
@media (max-width: 786px) {
  .main-head {
    /* background: linear-gradient(180deg, #3655F8 0%, #43BBFF 100%); */
    /* background-color: #222222; */
    transition: all 0.3s ease;
    background: var(--nav-bg-color);
  }
  .logo {
    color: var(--logo-color);
  }
  .hometxtbox p {
    padding: 0rem;
    color: var(--paragraph-color);
  }
}
@media screen and (max-width: 500px) {
  body {
    max-width: 100vw;
  }
  .hometxtbox {
    max-width: 90vw;
    top: 10vh;
    position: relative;
    display: block;
  }
  .homediv p {
    width: 100%;
    font-size: 1.1rem;
  }
  .animatedboybox {
    bottom: 0;
  }
}
