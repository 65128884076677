$inputgroupbg:linear-gradient(0deg,rgb(136, 173, 230),rgb(46, 169, 252));
.contactcontainer{
    width: 90%;
    margin: 0 auto;
    padding-top: 10vh;
}
:root {
  --switch-text:#1d1d1d;
  --contact-bg:#ffffff;
  --box-shadow: 10px 10px 0px 0px #80808083;
  --border:1px solid #ccc;
  
}
.darkmode{
  --switch-text:#f2f2f2;
  --contact-bg:#2c2c2c;
  --box-shadow: 10px 10px 0px 0px #80808083;
  --border:1px solid transparent;
}
.contact_container {
    margin-bottom: 10vh;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: #ccc;
    padding: 0 5%;
    .contacttext{
      h1{
        margin-bottom: 0;
      }
      h2{
        width: 500px;
        line-height: 1.4;
      }
      .contacticons{
        display: flex;
        align-items: center;
        i{
          color: rgb(0, 119, 255);
          font-size: 2rem;
          margin-right: 15px;
        }
        span{
          color: var(--switch-text);
          font-size: 1.2rem;
          opacity: 0.8;
        }
      }
    }
  .contactform {
    width:350px;
    max-width:90%;
    // margin: 0 auto;
    // border-radius: 8px;
    border: var(--border);
    background-color: var(--contact-bg);
    padding: 1rem 2rem;
    background-color: var(--contact-bg);
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    // box-shadow: 10px 10px 0px #ccc;
    box-shadow: var(--box-shadow);
    .inputgroup {
        // background: $inputgroupbg;
        color: var(--switch-text);
        padding: 1rem;
        // margin-bottom:0.4rem;
        border-radius: 8px;
      label{
        display: block;
        margin-bottom: 0.4rem;
        color:rgb(28, 126, 255);
        letter-spacing: 0.123rem;

      }
      input {
        all: unset;
        padding:0.5rem 5%;
        border-bottom: 1px solid #ccc;
        width: 90%;
      }
      textarea{
        all: unset;
          padding:5%;
          width: 90%;
          height:8rem;
          resize: vertical;
          // border-radius: 8px;
          outline: 1px solid #ccc;
          // background-color: aliceblue;
      }
      button{
        all: unset;
        padding: 1rem 20px;
        width: 80px;
        text-align: center;
        color: #fff;
        background-color: rgb(28,126,255);
        box-shadow: inset 0px 0px  0.01px rgb(28, 126, 255);
        transition:all 0.5s ease-in-out;
        border-radius: 5px;
        cursor: pointer;
        &:hover{
          box-shadow: inset 120px 0px  0.01px rgb(132, 0, 255);
        }
      }
    }
  }
}


@media screen and (max-width:1085px) {
  .contactcontainer{
    margin: 0;
    width: 100%;
    padding-top:5vh;
    overflow-x: hidden;
  }
  .contact_container {
    flex-direction: column;
    width: 100%;
    padding: 0;
    // margin:0;
    .contacttext{
      margin-bottom: 3rem;
      text-align: center;
      h2{
        width: 80%;
        margin: 0 auto;

      }
      .contacticons{
        margin-top: 0.5rem;
        justify-content: center;
      }
    }
    .contactform{
      width:80%;
      padding: 1rem 1rem;
      input,textarea{
        width: 100%;
      }
    }
  }
}