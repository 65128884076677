.aboutme__heading {
  text-align: center;
  font-weight: 700;
  background: linear-gradient(268deg, #b445fc, #0ebff2);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  letter-spacing: 0.125rem;
  line-height: 1.5;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 0px 30px;
}

.aboutme_section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .aboutme_txt {
    // background-color: #0ebff2;
    width: 40%;
    p {
      line-height: 1.7;
      font-size: large;
    }
    .hori-line {
      height: 5px;
      width: 250px;
      background-color: #0ebff2;
      background: linear-gradient(268deg, #b445fc, #0ebff2);
    }
    .aboutme_icons {
      padding: 1rem 0;
      a {
        margin-right: 1.5rem;
        i {
          font-size: 30px;
          background: linear-gradient(0deg, #b445fc, #0ebff2);
          -webkit-text-fill-color: transparent;
          background-clip: text;
          -webkit-background-clip: text;
          transition: all 0.3s ease-in-out;
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
  .aboutme_img {
    height: 250px;
    width: 250px;
    background: linear-gradient(268deg, #b445fc, #0ebff2);
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: baseline;
    box-shadow: 0px 0px 5px 2px #0ebff2;
    img {
      transform: translateY(20px);
      width: 100%;
    }
  }
}

.experiences {
  margin: 15vh 0 5vh 0;
  h2 {
    text-align: center;
    font-weight: 700;
    background: linear-gradient(268deg, #b445fc, #0ebff2);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    letter-spacing: 0.125rem;
    font-size: 3rem;
    line-height: 1.5;
    text-shadow: rgba(255, 255, 255, 0.5) 0px 0px 30px;
  }
  .experience {
    width: 60%;
    border-radius: 8px;
    background-color: #ccc;
    margin: 1rem auto;
    padding: 2rem 0.5rem;
    display: flex;
    justify-content: space-between;

    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    .company_logo {
      width: 30%;
      display: grid;
      place-items: center;
    }
    .experience_text {
      width: 70%;
      h3 {
        line-height: 1.7;
        letter-spacing: 0.123rem;
        font-weight: 900;
        opacity: 0.8;
        padding: 0.4rem 1rem;
        border-left: 4px solid #0ebff2;
        background: linear-gradient(268deg, #b445fc, #0ebff2);
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
      }
      h4,
      h5 {
        opacity: 0.8;
      }
    }
  }
  
}

.about_skills{
  margin: 5rem;
  // background-color: #0ebff2;
  padding: 1rem 0 5rem 0;
  h2{
    text-align: center;
    font-weight: 700;
    font-size: 3rem;
    background: linear-gradient(268deg, #b445fc, #0ebff2);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    letter-spacing: 0.125rem;
    line-height: 1.5;
    text-shadow: rgba(255, 255, 255, 0.5) 0px 0px 30px;
  }
  .about_skill_container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    img{
      height: 150px;
      width: 150px;
      margin: 1rem;
      border-radius: 50%;
      box-shadow: 0px 0px 10px 1px #0ebff2;
    }
  }
}

@media screen and (max-width: 800px) {
  .aboutme_section {
    flex-direction: column;
    .aboutme_txt {
      margin-top: 2rem;
      width: 100%;
      .hori-line {
        width: 100%;
      }
      .aboutme_icons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .experience {
    width: 90% !important;
    min-width: 280px;
    flex-direction: column;
    padding: 0;
    .company_logo {
      width: 100% !important;
      img {
        margin: 0 auto;
      }
    }
    .experience_text {
      // border: 2px solid yellow;
      width: 100% !important;
      text-align: center;
      h3 {
        border-left: none !important;
        background: linear-gradient(268deg, #b445fc, #0ebff2);
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
      }
    }
  }
}
