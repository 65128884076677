:root {
  --bg-color: rgba(17, 17, 17);
  --bg-color: #ffffff;
  --nav-bg-color: linear-gradient(180deg, #3655f8 0%, #43bbff 100%);
  --text-color: #ffffff;
  --logo-color: #000000;
  --heading-color: #424242;
  --paragraph-color: #484848;
  --mobile-nav-bg: #ebebeb;
  --mobil-nav-link-color: #1d1d1d;
  --switch-text:#1d1d1d;
}

.darkmode {
  /* --bg-color:#232323; */
  --bg-color: #1d1d1d;
  --nav-bg-color: #222222;
  --heading-color: #ececec;
  --paragraph-color: #f4f4f4;
  --text-color: #ffffff;
  --logo-color: #f3f3f3;
  --mobile-nav-bg: #333333;
  --mobil-nav-link-color: #ffffff;
  --switch-text:#ffffff;
}
h1{
  color: var(--heading-color);
}
p{
  color: var(--paragraph-color);
}
.aboutcontainer{
  min-height:150vh;
  margin-bottom: 10vh;
  width: 90%;
  padding: 0 5%;
  background-color: #3656f800;

}
.navcloth {
  height: 10vh;
  width: 600px;
  background: linear-gradient(180deg, #3655f8 0%, #43bbff 100%);
  position: fixed;
  top: 0;
  right: 0;
  z-index:100;
  border-radius: 0 0 0 28px;
  /* clip-path: polygon(5% 0, 100% 0%, 100% 100%, 0 100%); */
}
@media screen and (max-width:800px){
  .navcloth{
    display: none;
  }
}
@media screen and (max-width:960px) {
  .navcloth{
    max-width: 20vw;
  }
}