:root{
    --heading-color: #424242;
}
.portfoliocontainer{
    width: 90%;
    padding:2.5rem 5%;
    overflow-x: hidden !important;
}

.portfolioh1{
    text-align: center;
    text-decoration: underline;
}


.portfolio_box_container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding-top: 3rem;
    /* border: 2px solid red; */
    /* padding: 1rem; */
}

/* .left{
    flex-direction: row-reverse;
    text-align: right;
}
.left h2{
    border-left: 5px solid transparent;
    border-right: 5px solid blue;
} */
.projectbox{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
  
}
.projecttxt{
    padding: 1rem 3rem 0 3rem;
    width: 60%;
    /* border: 2px solid red; */
    min-height: 100%;
    line-height: 1.7;
}
.projecttxt h2{
    /* text-decoration-line: underline;
    text-decoration-style: wavy;
    text-decoration-color: rgb(1, 69, 255); */
    margin-top: 3rem;
    padding: 0.5rem 0.5rem;
    border-left: 5px solid blue;
}
.projecttxt p{
    font-size: large;
    /* text-align: justify; */
}
.projecttxt pre{
    background-color: #ccc;
    width: max-content;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-size: large;
}
.projectimg{
    width: 40%;
    /* border: 2px dashed rgb(0, 4, 255); */
}
.projectimg img{
    transform: all 0.3s ease;
    aspect-ratio: 10/9;
}
.projectbox a{
    text-decoration: none;
}

.pbtn{
   
    all: unset;
    padding: 0.6rem 1rem;
    cursor: pointer;
    color: #fff;
    border-radius: 6px;
    text-decoration: none;
    background:linear-gradient(45deg, rgb(0, 136, 248), rgb(89, 89, 253));
}


.vghrepos ,.vghrepos a{
    text-align: center;
    color:var(--heading-color)
}


@media screen and (max-width:1005px) {
    .projectbox{
        flex-direction: column-reverse;
        align-items: center;
    }
    .projectbox .projectimg{
        width: 100%;
        display: grid;
        place-items:center;
        padding: 0;
    }
    .projectbox .projectimg img{
        height: 300px;
        aspect-ratio: 10/9;
    }
    .projectbox .projecttxt{
        padding: 1rem ;
        width: 100%;
    }
}