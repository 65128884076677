.contactcontainer {
  width: 90%;
  margin: 0 auto;
  padding-top: 10vh;
}

:root {
  --switch-text:#1d1d1d;
  --contact-bg:#ffffff;
  --box-shadow: 10px 10px 0px 0px #80808083;
  --border:1px solid #ccc;
}

.darkmode {
  --switch-text:#f2f2f2;
  --contact-bg:#2c2c2c;
  --box-shadow: 10px 10px 0px 0px #80808083;
  --border:1px solid transparent;
}

.contact_container {
  margin-bottom: 10vh;
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 5%;
}

.contact_container .contacttext h1 {
  margin-bottom: 0;
}

.contact_container .contacttext h2 {
  width: 500px;
  line-height: 1.4;
}

.contact_container .contacttext .contacticons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.contact_container .contacttext .contacticons i {
  color: #0077ff;
  font-size: 2rem;
  margin-right: 15px;
}

.contact_container .contacttext .contacticons span {
  color: var(--switch-text);
  font-size: 1.2rem;
  opacity: 0.8;
}

.contact_container .contactform {
  width: 350px;
  max-width: 90%;
  border: var(--border);
  background-color: var(--contact-bg);
  padding: 1rem 2rem;
  background-color: var(--contact-bg);
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}

.contact_container .contactform .inputgroup {
  color: var(--switch-text);
  padding: 1rem;
  border-radius: 8px;
}

.contact_container .contactform .inputgroup label {
  display: block;
  margin-bottom: 0.4rem;
  color: #1c7eff;
  letter-spacing: 0.123rem;
}

.contact_container .contactform .inputgroup input {
  all: unset;
  padding: 0.5rem 5%;
  border-bottom: 1px solid #ccc;
  width: 90%;
}

.contact_container .contactform .inputgroup textarea {
  all: unset;
  padding: 5%;
  width: 90%;
  height: 8rem;
  resize: vertical;
  outline: 1px solid #ccc;
}

.contact_container .contactform .inputgroup button {
  all: unset;
  padding: 1rem 20px;
  width: 80px;
  text-align: center;
  color: #fff;
  background-color: #1c7eff;
  -webkit-box-shadow: inset 0px 0px 0.01px #1c7eff;
          box-shadow: inset 0px 0px 0.01px #1c7eff;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
  cursor: pointer;
}

.contact_container .contactform .inputgroup button:hover {
  -webkit-box-shadow: inset 120px 0px 0.01px #8400ff;
          box-shadow: inset 120px 0px 0.01px #8400ff;
}

@media screen and (max-width: 1085px) {
  .contactcontainer {
    margin: 0;
    width: 100%;
    padding-top: 5vh;
    overflow-x: hidden;
  }
  .contact_container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .contact_container .contacttext {
    margin-bottom: 3rem;
    text-align: center;
  }
  .contact_container .contacttext h2 {
    width: 80%;
    margin: 0 auto;
  }
  .contact_container .contacttext .contacticons {
    margin-top: 0.5rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .contact_container .contactform {
    width: 80%;
    padding: 1rem 1rem;
  }
  .contact_container .contactform input, .contact_container .contactform textarea {
    width: 100%;
  }
}

.aboutme__heading {
  text-align: center;
  font-weight: 700;
  background: linear-gradient(268deg, #b445fc, #0ebff2);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  letter-spacing: 0.125rem;
  line-height: 1.5;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 0px 30px;
}

.aboutme_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.aboutme_section .aboutme_txt {
  width: 40%;
}

.aboutme_section .aboutme_txt p {
  line-height: 1.7;
  font-size: large;
}

.aboutme_section .aboutme_txt .hori-line {
  height: 5px;
  width: 250px;
  background-color: #0ebff2;
  background: linear-gradient(268deg, #b445fc, #0ebff2);
}

.aboutme_section .aboutme_txt .aboutme_icons {
  padding: 1rem 0;
}

.aboutme_section .aboutme_txt .aboutme_icons a {
  margin-right: 1.5rem;
}

.aboutme_section .aboutme_txt .aboutme_icons a i {
  font-size: 30px;
  background: -webkit-gradient(linear, left bottom, left top, from(#b445fc), to(#0ebff2));
  background: linear-gradient(0deg, #b445fc, #0ebff2);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.aboutme_section .aboutme_txt .aboutme_icons a i:hover {
  opacity: 0.5;
}

.aboutme_section .aboutme_img {
  height: 250px;
  width: 250px;
  background: linear-gradient(268deg, #b445fc, #0ebff2);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  -o-object-fit: cover;
     object-fit: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: baseline;
      -ms-flex-pack: baseline;
          justify-content: baseline;
  -webkit-box-shadow: 0px 0px 5px 2px #0ebff2;
          box-shadow: 0px 0px 5px 2px #0ebff2;
}

.aboutme_section .aboutme_img img {
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  width: 100%;
}

.experiences {
  margin: 15vh 0 5vh 0;
}

.experiences h2 {
  text-align: center;
  font-weight: 700;
  background: linear-gradient(268deg, #b445fc, #0ebff2);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  letter-spacing: 0.125rem;
  font-size: 3rem;
  line-height: 1.5;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 0px 30px;
}

.experiences .experience {
  width: 60%;
  border-radius: 8px;
  background-color: #ccc;
  margin: 1rem auto;
  padding: 2rem 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.experiences .experience .company_logo {
  width: 30%;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.experiences .experience .experience_text {
  width: 70%;
}

.experiences .experience .experience_text h3 {
  line-height: 1.7;
  letter-spacing: 0.123rem;
  font-weight: 900;
  opacity: 0.8;
  padding: 0.4rem 1rem;
  border-left: 4px solid #0ebff2;
  background: linear-gradient(268deg, #b445fc, #0ebff2);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.experiences .experience .experience_text h4,
.experiences .experience .experience_text h5 {
  opacity: 0.8;
}

.about_skills {
  margin: 5rem;
  padding: 1rem 0 5rem 0;
}

.about_skills h2 {
  text-align: center;
  font-weight: 700;
  font-size: 3rem;
  background: linear-gradient(268deg, #b445fc, #0ebff2);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  letter-spacing: 0.125rem;
  line-height: 1.5;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 0px 30px;
}

.about_skills .about_skill_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.about_skills .about_skill_container img {
  height: 150px;
  width: 150px;
  margin: 1rem;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 10px 1px #0ebff2;
          box-shadow: 0px 0px 10px 1px #0ebff2;
}

@media screen and (max-width: 800px) {
  .aboutme_section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .aboutme_section .aboutme_txt {
    margin-top: 2rem;
    width: 100%;
  }
  .aboutme_section .aboutme_txt .hori-line {
    width: 100%;
  }
  .aboutme_section .aboutme_txt .aboutme_icons {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .experience {
    width: 90% !important;
    min-width: 280px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0;
  }
  .experience .company_logo {
    width: 100% !important;
  }
  .experience .company_logo img {
    margin: 0 auto;
  }
  .experience .experience_text {
    width: 100% !important;
    text-align: center;
  }
  .experience .experience_text h3 {
    border-left: none !important;
    background: linear-gradient(268deg, #b445fc, #0ebff2);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
}
