
@media (max-width: 960px){
    .burger{
        display: flex;
        position :fixed;
        right: 5%;
    }
}
@media (max-width:800px){
    .burger{
        display: flex;
        position :relative;
        right:0;
    }
}