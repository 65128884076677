:root {
    --bg-color: rgba(17, 17, 17);
    --bg-color: #ffffff;
    --nav-bg-color: linear-gradient(180deg, #3655f8 0%, #43bbff 100%);
    --text-color: #ffffff;
    --logo-color: #000000;
    --heading-color: #424242;
    --paragraph-color: #484848;
    --mobile-nav-bg: #ebebeb;
    --mobil-nav-link-color: #1d1d1d;

    --service-box-bg:#fdfdfd;
    --service-icon:#3655f8;
    --service-box-border:#ccc;
}
.servicescontainer{
    width: 90%;
    padding:2.5rem 5%;
}

.servicesh1{
    text-align: center;
    text-decoration: underline;
}

.services_container{
    margin-top: 3rem;
    display: flex;
    justify-content: space-around;
    /* flex-direction: column; */
    flex-wrap: wrap;
}
.servicebox{
    height: 250px;
    width:380px;
    margin-bottom: 3rem;
    background-color:var(--service-box-bg);
    border: 1px solid var(--service-box-border);
    border-radius: 8px;
    text-align: center;
    padding: 0.5rem 0rem;
    transition: all 0.3s ease;
    cursor: pointer;
}
.serviceicon{
    font-size: 2.5rem;
    display: grid;
    place-items: center;
    width:80px;
    height: 80px;
    margin: 1rem  auto;
    background-color: transparent;
    border-radius: 50%;
    transition: all 0.3s ease;
    /* background-color: #43bbff; */
  
    /* border: 1px solid red; */
}
.serviceicon i{
   
    color: var(--service-icon);
    transform: scale(1.2);
    padding: 1rem;
    /* height: 50px;
    width: 50px; */
    
    transition: all 0.3s ease-in-out;
}
.servicetitle{
    font-family: sans-serif;
    font-size: large;
}
.servicebox p{
    padding: 0 1rem;
}
.servicebox:hover{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.servicebox:hover .serviceicon{
    background-color: #3655f8;
}
.servicebox:hover .serviceicon i{
   
    transform: scale(0.8);
    color: #ffffff;
}